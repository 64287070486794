import '/assets/styles/partials/tabs.scss';
import PropTypes from 'prop-types'

import React, { useState, useEffect, useRef } from 'react'

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const Tabs = ({plain, ...props}) => {
	const containerRef = useRef(null);

	let activeIndex = 0;
	let children = React.Children.toArray(props.children);
	children.forEach((tab, index) => {
		if (tab.props.active) {
			activeIndex = index;
		}
	})

	useEffect(() => {
		if(props.activeTab) {
			setActiveTab(props.activeTab);
		}
	}, [props.activeTab])

	const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : (children && children.length > 0 ? children[activeIndex].props.index : false));
	const changeTab = (tab) => {
		setActiveTab(tab);

		if (props.onChange) {
			props.onChange(tab);
		}

	}

	if(children && children.length > 0){
		return (
			<section
				className={props.className + (plain ? ' plain' : '') + ' tabs-container'}
				ref={containerRef}>
				<div className={"tabs-labels" + (props.narrow ? ' narrow' : '')/*+ (children.length < 5 ? ' narrow' : '')*/}>
					<div className="labels-innerwrap">
						{children.map((child) => {
							const { index, className } = child.props
							const isSingle = children.length > 1 ? false : true;
							const BtnElem = !isSingle ? 'button' : 'span';
							const onClickAction = !isSingle ? () => {
								changeTab(index)
							} : undefined;

							return (
								<div key={index} className="labels-item">
									<BtnElem
										disabled={child.props.disabled}
										className={'item-btn ' + (className || '') + (isSingle ? ' single' : '') + (index === activeTab ? ' active' : '')}
										onClick={onClickAction}
										type={!isSingle ? 'button' : undefined}
									>
										{child.props.icon &&
											<Icon className="btn-icon" name={child.props.icon} />
										}
										<span dangerouslySetInnerHTML={{__html: child.props.label ? child.props.label.split('\\n').join('<br />') : 'Başlık Yok'}} />
									</BtnElem>
								</div>
							)
						})}
					</div>
				</div>
				<div className="tabs-items">
					{children.map(child => {
						const { index, children, className } = child.props
						return (
							<Tab
								index={index}
								className={className || ''}
								key={index}
								alwaysRender={props.alwaysRender}
								active={index === activeTab}>
								{children}
							</Tab>
						)
					})}
				</div>
			</section>
		)
	}
	else {
		return false;
	}
}

Tabs.propTypes = {
	plain: PropTypes.bool,
	children: PropTypes.node,
	activeTab: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
	className: PropTypes.string,
	narrow: PropTypes.bool,
	alwaysRender: PropTypes.bool,
	onChange: PropTypes.func,
}

const Tab = ({ className, ...props}) => {

	const [active, setActive] = useState(props.active);
	const [show, setShow] = useState(props.active);

	useEffect(() => {
		if (props.active) {
			setTimeout(() => {
				setActive(true);
				setTimeout(() => {
					setShow(true);
				}, 50)
			}, 350)
		} else {
			setShow(false);
			setTimeout(() => {
				setActive(false);
			}, 350)
		}
	}, [props.active]);

	if (active || props.alwaysRender) {
		return (
			<div className={'items-tab ' + className + (active ? ' active' : '') + (show ? ' show ' : '')}>
				{props.children}
			</div>
		)
	}
	else { return false }
}

Tab.propTypes = {
	className: PropTypes.string,
	index: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
	active: PropTypes.bool,
	children: PropTypes.node,
	alwaysRender: PropTypes.bool,
}

Tabs.defaultProps = {
	className: '',
	title: false,
	alwaysRender: false,
}

export default Tabs;