import '/assets/styles/sections/product-carousel.scss';
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types'

// Partials
import Tabs from '/views/partials/tabs'
import Productbox from '/views/partials/productbox'
import Slider from '/views/partials/slider'
import SectionTitle from '/views/partials/section-title';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

const ProductCarousel = ({ data: dataProp, dataFetcher, className, slides, noWrap, tabs = false, id = 'productcarousel' }) => {
	const [data, setData] = useState(dataProp ?? false);

	const filteredData = useMemo(() => {
		return (data || []).filter(group => !(!group || (group.products && group.products.length === 0)));
	}, [data])

	useEffect(() => {
		if(dataProp) {
			setData(dataProp);
		}
	}, [dataProp])

	useEffect(() => {
		if(!data && dataFetcher) {
			dataFetcher().then((payload) => {
				setData(payload);
			}).catch(() => {
				setData([]);
			})
		}
	}, [dataFetcher, data]);

	if(filteredData.length === 0) {
		return null;
	}

	if(tabs) {
		return (
			<Tabs id={id} className={'section productcarousel' + (className ? ' ' + className : '') + (noWrap ? ' no-wrap' : ' wrap')}>
				{filteredData.map((section, nth) => (
					<div
						key={section.key ?? nth}
						index={section.key ?? nth}
						icon={section.icon}
						label={section.title}>
						<Carousel
							products={section.products}
							slides={slides}
							index={nth}
							noWrap={noWrap}
							id={`${id}-carousel-${section.key ?? nth}`}
							className={className} />
					</div>
				))}
			</Tabs>
		)
	}
	else {
		return (
			<section id={id} className={'section productcarousel' + (className ? ' ' + className : '') + (noWrap ? ' no-wrap' : ' wrap')}>
				{filteredData.map((section, nth) => (
					<div
						className="productcarousel-group"
						// icon={section.icon}
						key={section.key ? section.key : nth}>
						<div className={`group-title${noWrap ? '' : ' wrapper'}`}>
							<SectionTitle>{section.title}</SectionTitle>
						</div>
						<Carousel
							products={section.products}
							slides={slides}
							index={nth}
							noWrap={noWrap}
							id={`${id}-carousel-${section.key ?? nth}`}
							className={className} />
					</div>
				))}
			</section>
		)
	}
}

ProductCarousel.propTypes = {
	data: PropTypes.array,
	dataFetcher: PropTypes.func,
	className: PropTypes.string,
	slides: PropTypes.array,
	noWrap: PropTypes.bool,
	tabs: PropTypes.bool,
}

const Carousel = ({ products, slides, index, noWrap, className, id = "productcarousel-slider" }) => {
	const { mobile } = useBreakpoints();

	const displayProducts = products && products.length ? products : [false, false, false, false, false, false, false, false, false];

	const slideCount = mobile ? 2 : (slides ? slides : (noWrap ? 5 : 5));

	return (
		<div className={'productcarousel-wrap' + (noWrap ? '' : ' wrap wrapper')}>
			{slideCount < displayProducts.length ?
				<Slider
					id={id}
					key={'product-carousel-' + className + (mobile ? 'mobile' : 'desktop')}
					className="productcarousel-slider"
					navigation={!mobile}
					params={{
						loopAdditionalSlides: mobile ? 1 : 0,
						loop: true,
						slidesPerView: slideCount,
					}}>
					{displayProducts.map((product, nth) => (
						<div className="productcarousel-slide" key={`carousel-${index}-${nth}`}>
							<Productbox product={product} />
						</div>
					))}
				</Slider>
				:
				<div className="productcarousel-slider static">
					{displayProducts.map((product, nth) => (
						<div className="productcarousel-slide" key={`carousel-${index}-${nth}`}>
							<Productbox product={product} />
						</div>
					))}
				</div>
			}
		</div>
	)
}

ProductCarousel.defaultProps = {
	noWrap: false,
}

Carousel.propTypes = {
	products: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
	slides: PropTypes.array,
	index: PropTypes.number,
	noWrap: PropTypes.bool,
	className: PropTypes.string,

}

export default ProductCarousel;