import '/assets/styles/partials/section-title.scss';
import PropTypes from 'prop-types';

const SectionTitle = ({ children, tag = 'h2', className = '' }) => {
	const Tag = tag;
	return (
		<Tag className={`section-title-component ${className}`}>
			{children}
		</Tag>
	)
}

SectionTitle.propTypes = {
	children: PropTypes.node,
	tag: PropTypes.string,
	className: PropTypes.string,
}

export default SectionTitle